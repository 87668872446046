export const loadingDatas = [
  {
    name: 'skeleton-1',
  },
  {
    name: 'skeleton-2',
  },
  {
    name: 'skeleton-3',
  },
  {
    name: 'skeleton-4',
  },
  {
    name: 'skeleton-5',
  },
  {
    name: 'skeleton-6',
  },
  {
    name: 'skeleton-7',
  },
  {
    name: 'skeleton-8',
  },
  {
    name: 'skeleton-9',
  },
  {
    name: 'skeleton-10',
  },
  {
    name: 'skeleton-11',
  },
  {
    name: 'skeleton-12',
  },
]

export const loadingPacksDatas = [
  {
    name: 'skeleton-1',
  },
  {
    name: 'skeleton-2',
  },
  {
    name: 'skeleton-3',
  },
  {
    name: 'skeleton-4',
  },
]
