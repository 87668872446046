import React, { useEffect, useMemo, useState } from 'react'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import ProductCard from './ProductCard'
import {
  checkProductAvailability,
  columnLayout,
  thumbnailRatio,
} from '@/utils/utils'
import { loadingDatas } from '../Global/LoadingDatas'
import Skeleton from '@material-ui/lab/Skeleton'
import CMSText from '../Global/CMSText'
import { useTranslation } from 'react-i18next'
import { ShopConfig } from '@/interfaces/common/shopConfig'
import { Image, ProductGroup } from '@/typings/base'
import { PrismicGeneralConfig } from '@/interfaces/common/prismicGeneralConfig'
import { PrismicProduct } from '../../interfaces/common/prismicProduct'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFull: {
      marginTop: theme.spacing(3),
    },
    rootColumn: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
      },
    },
    col: {},
    md5: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    skeletonCard: {
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
    },
    skeletonImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    },
    skeletonContent: {
      padding: 10,
    },
  })
)

type Tprops = {
  products: ProductGroup
  availableProductsOnly: boolean
  shopData?: ShopConfig
  mainData?: PrismicGeneralConfig
  settings?: any
  stock: any
  loadMore: boolean
  hidePrice?: boolean
  basket?: any
  takeback?: any
  isDisplayedPrice?: boolean
  isLoadingProductsAndStocks: boolean
  isSearch?: boolean
  isSecondHandTaxon?: boolean
  productSettings: PrismicProduct
}

const ProductsTab = ({
  products,
  stock,
  shopData,
  mainData,
  settings,
  availableProductsOnly,
  loadMore,
  hidePrice,
  basket,
  takeback,
  isDisplayedPrice = true,
  isLoadingProductsAndStocks,
  isSearch = false,
  isSecondHandTaxon,
  productSettings,
}: Tprops) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [unavailableProductCount, setUnavailableProductCount] = useState(0)
  const layout = settings?.page_shop_layout
  const columns = settings?.page_shop_desktop_columns
  const thumbRatio = settings?.thumbnail_ratio

  const cardWidth = settings?.medium_image_size
  const aspectRatio = thumbnailRatio(thumbRatio)
  const gridItemWidth = columnLayout(columns)
  const isFiveColumns = gridItemWidth === '20%'
  const productToShow = products?.items?.items

  const showImagesSlider = useMemo(
    () => mainData?.data?.show_product_item_slider,
    [mainData?.data?.show_product_item_slider]
  )

  const showSizedOnHover = mainData?.data?.sizes_on_hover

  useEffect(() => {
    if (productToShow && stock && stock.stock && !isLoadingProductsAndStocks) {
      let i = 0
      productToShow?.map((product) => {
        if (product.enabled) {
          if (availableProductsOnly) {
            const productAvailability = checkProductAvailability(stock, product)
            if (
              !productAvailability.available &&
              !productAvailability.secondHandAvailable
            ) {
              i++
            }
          }
        }
      })
      setUnavailableProductCount(i)
    }
  }, [productToShow, stock.stock, isLoadingProductsAndStocks])

  const productToShowComponent = useMemo(
    () =>
      productToShow?.map((product, index) => {
        const productAvailability = checkProductAvailability(stock, product)
        let thumb: Image[] = []
        if (
          isSecondHandTaxon &&
          productAvailability.secondHandImage?.length > 0
        ) {
          thumb = productAvailability.secondHandImage.concat(product?.images)
        } else {
          thumb = product?.images
        }

        if (!showImagesSlider) {
          thumb = thumb.slice(0, 1)
        }

        if (availableProductsOnly && product.enabled) {
          if (
            productAvailability.available ||
            productAvailability.secondHandAvailable
          ) {
            return (
              <Grid
                item
                key={product.code}
                xs={6}
                md={4}
                lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
                className={isFiveColumns ? classes.md5 : classes.col}
              >
                <ProductCard
                  addButton
                  key={product.code}
                  settings={settings}
                  shopData={shopData}
                  productSettings={productSettings}
                  index={index}
                  productCode={product.code}
                  slug={product.slug}
                  name={product.name}
                  thumb={thumb}
                  thumbRatio={aspectRatio}
                  thumbWidth={cardWidth}
                  product={product}
                  hidePrice={hidePrice}
                  basket={basket}
                  takeback={takeback}
                  isDisplayedPrice={isDisplayedPrice}
                  isSecondHandTaxon={isSecondHandTaxon}
                  showSizesOnHover={showSizedOnHover}
                  showImagesSlider={showImagesSlider}
                />
              </Grid>
            )
          } else {
            return null
          }
        } else {
          return (
            <Grid
              item
              key={product.code}
              xs={6}
              md={4}
              lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
              className={isFiveColumns ? classes.md5 : classes.col}
            >
              <ProductCard
                addButton
                key={product.code}
                shopData={shopData}
                settings={settings}
                index={index}
                productSettings={productSettings}
                productCode={product.code}
                slug={product.slug}
                name={product.name}
                thumb={thumb}
                thumbRatio={aspectRatio}
                thumbWidth={cardWidth}
                product={product}
                hidePrice={hidePrice}
                basket={basket}
                isDisplayedPrice={isDisplayedPrice}
                takeback={takeback}
                isSecondHandTaxon={isSecondHandTaxon}
                mainData={mainData}
                showSizesOnHover={showSizedOnHover}
                showImagesSlider={showImagesSlider}
              />
            </Grid>
          )
        }
      }),
    [availableProductsOnly, productToShow, shopData, stock, productSettings]
  )

  return (
    <>
      <Grid
        container
        component="section"
        spacing={3}
        className={
          layout
            ? `shop__grid ${classes.rootColumn}`
            : `shop__grid ${classes.rootFull}`
        }
      >
        <>
          {loadMore &&
            productToShow &&
            stock &&
            stock.stock &&
            !isLoadingProductsAndStocks &&
            productToShowComponent}
          {isLoadingProductsAndStocks &&
            loadingDatas?.map((loadingData) => (
              <Grid
                item
                key={`item-${loadingData.name}`}
                xs={6}
                md={4}
                lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
                className={isFiveColumns ? classes.md5 : classes.col}
              >
                <div className={classes.skeletonCard}>
                  <div data-ratio={aspectRatio}>
                    <Skeleton variant="rect" className={classes.skeletonImg} />
                  </div>
                  <div className={classes.skeletonContent}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="30%" />
                  </div>
                </div>
              </Grid>
            ))}
          {!isLoadingProductsAndStocks &&
            unavailableProductCount >= productToShow?.length && (
              <Grid item xs={12}>
                <Typography variant={'h4'} align="center">
                  {isSearch ? (
                    <CMSText
                      data={mainData?.data?.search_not_found_text}
                      defaultText={t('texts:shop:search_not_found')}
                      asText
                    />
                  ) : (
                    <CMSText
                      data={shopData?.data?.no_stock_available}
                      defaultText={t('texts:shop:no_stock_available')}
                      asText
                    />
                  )}
                </Typography>
              </Grid>
            )}
          {productToShow &&
            stock &&
            !loadMore &&
            stock.stock &&
            !isLoadingProductsAndStocks &&
            productToShowComponent}
        </>
      </Grid>
    </>
  )
}

export default ProductsTab
