import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Typography } from '@material-ui/core'
import { getPromotions } from '../../redux/actions/promotions'
import { connect } from 'react-redux'
import CMSText from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      margin: theme.spacing(0, 0, 2),
      padding: theme.spacing(3, 2, 1),
      background: theme.palette.primaryTransparent.light,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0.5, 1),
      },
    },
    title: {},
    subtitle: {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.caption.fontSize,
      },
    },
    plans: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        gap: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        gap: theme.spacing(2),
      },
    },
    plan: {
      margin: theme.spacing(4, 0, 2),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1, 0),
      },
    },
    priceRow: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {},
    },
    priceNumber: {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    priceFrequency: {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.caption.fontSize,
      },
    },
    articles: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
    },
    numberArticles: {
      display: 'inline-flex',
      padding: 0,
      width: '3ch',
      height: '3ch',
      lineHeight: 1,
      fontWeight: 700,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      margin: theme.spacing(0.5),
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      [theme.breakpoints.down('lg')]: {
        fontSize: '80%',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '60%',
      },
    },
    articleText: {
      lineHeight: 1,
      textAlign: 'left',
    },
    numberText: {
      lineHeight: 1,
      [theme.breakpoints.down('lg')]: {
        fontSize: '60%',
      },
    },
  })
)

const mapStateToProps = (state) => ({
  promotions: state.promotions,
})

const mapDispatchToProps = {
  getPromotions: getPromotions,
}

type Tprops = {
  doc: any
  t: any
  getPromotions: () => void
  promotions: any
}

const FlatRate = ({ doc, t, promotions, getPromotions }: Tprops) => {
  useEffect(() => {
    if (!promotions?.items) {
      getPromotions()
    }
  }, [])
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography
        variant={'h3'}
        className={clsx(classes.title, 'flatRate__subtitle')}
      >
        <CMSText
          asText
          data={doc?.data?.title}
          defaultText={t('texts:shop:classic_sub:our_rates')}
        />
      </Typography>
      <Typography
        variant={'body2'}
        className={clsx(classes.subtitle, 'flatRate__subtitle')}
      >
        <CMSText
          asText
          data={doc?.data?.subtitle}
          defaultText={t('texts:shop:classic_sub:adjustable_rate')}
        />
      </Typography>
      <div className={classes.plans}>
        {/* Get classic subscription promotions to render all the flatrates */}
        {promotions?.items?.map((promotion, index) => {
          /* Design is currently suited for 3 flatrates to show */
          if (index < 3) {
            return (
              <div className={classes.plan} key={promotion.id}>
                <div className={clsx(classes.priceRow, 'flatRate__priceRow')}>
                  <Typography variant={'h2'} className={classes.priceNumber}>
                    {(
                      promotion.actions[0].configuration[
                        Object.keys(
                          promotions.items[0].actions[0].configuration
                        )[0]
                      ].amount / 100
                    ).toFixed(0)}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    className={classes.priceFrequency}
                  >
                    €{t('texts:general:month_without_space')}
                  </Typography>
                </div>
                <div className={clsx(classes.articles, 'flatRate__articles')}>
                  <div>
                    <Typography
                      variant={'body2'}
                      className={clsx(
                        classes.numberArticles,
                        'flatRate__articles__number'
                      )}
                      noWrap
                    >
                      {promotion.rules[0].configuration.to}
                    </Typography>
                  </div>
                  <div
                    className={clsx(
                      classes.articleText,
                      'flatRate__articles__text'
                    )}
                  >
                    <Typography
                      variant={'body2'}
                      component={'div'}
                      className={classes.numberText}
                    >
                      <b>
                        <CMSText
                          asText
                          data={
                            promotion?.rules[0]?.configuration?.to <= 1
                              ? doc?.data?.article_singular
                              : doc?.data?.articles_plural
                          }
                          defaultText={
                            promotion?.rules[0]?.configuration?.to <= 1
                              ? t('texts:shop:classic_sub:article')
                              : t('texts:shop:classic_sub:articles')
                          }
                        />
                      </b>
                    </Typography>
                    <Typography
                      variant={'body2'}
                      component={'div'}
                      className={classes.numberText}
                    >
                      <CMSText
                        asText
                        data={doc?.data?.maximum}
                        defaultText={t('texts:shop:classic_sub:maximum')}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FlatRate)
