// This a redux store for prismic information. We have to get prismic documents in the page component but we need
// the information in almost all components of the application. Save prismic documents in redux store permit to
// avoid props drilling and thus make the code easier to maintain.

import { RootState } from '../reducers/rootReducer'

export const ACTIONS = {
  SAVE_PRISMIC_DOCUMENTS: 'SAVE_PRISMIC_DOCUMENTS',
}

export const SAVE_PRISMIC_DOCUMENTS = (data: Partial<RootState['prismic']>) => {
  return { type: ACTIONS.SAVE_PRISMIC_DOCUMENTS, data }
}
