import { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core'

export const useSticky = (layout) => {
  const theme = useTheme()
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    let isMounted = true
    let observer: IntersectionObserver
    const handleSticky = () => {
      if (typeof window && !layout && isMounted) {
        const spyEl = document.querySelector('.spy')

        const handler = (entries) => {
          if (!entries[0].isIntersecting) {
            setIsSticky(true)
          } else {
            setIsSticky(false)
          }
        }
        const observeOpts = {
          rootMargin: `-${theme.spacing(4)}px 0px 0px 0px`,
          threshold: 0.5,
        }
        // create the observer
        observer = new IntersectionObserver(handler, observeOpts)
        observer.observe(spyEl)
      }
    }
    handleSticky()
    return () => {
      isMounted = false
      observer?.disconnect()
    }
  }, [typeof window, layout])
  return isSticky
}
